

























































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import ImgView from '@/components/ImgView/index.vue'
import RemarkModal from '@/views/common/remark-modal.vue'
import { getDicts, getDictName } from '@/utils/dict'
import { getExportExcel } from '@/utils/export'
import { CommonModule } from '@/store/modules/common'
import { DatePicker } from 'element-ui'
Vue.use(DatePicker)

@Component({
  components: {
    Table,
    Pagination,
    ImgView,
    RemarkModal
  }
})
export default class VisitorList extends Vue {
  $constants: any
  // 字典数据
  // 开门方式
  openDoorWayDicts: any = []
  // 人员类型
  personTypeDicts: any = []
  searchModel = {
    key: 'enterTime',
    value: null
  }
  openDoorType = ''
  enterDeviceId = ''
  listLoading = false
  tableHeader = [
    {
      key: 'faceImg',
      name: '面部识别',
      width: '10%',
      isSpecial: true
    },
    {
      key: 'snapTime',
      name: '进入时间',
      width: '16%'
    },
    {
      key: 'openType',
      name: '开门方式',
      width: '10%',
      isSpecial: true
    },
    {
      key: 'devGroupName',
      name: '位置',
      width: '10%'
    },
    {
      key: 'houseInfo',
      name: '开门信息',
      width: '14%'
    },
    {
      key: 'personType',
      name: '人员',
      width: '10%',
      isSpecial: true
    },
    {
      key: 'remark',
      name: '备注',
      width: '14%',
      remark: true
    },
    {
      key: 'opt',
      name: '操作',
      width: '10%',
      isSpecial: true
    }
  ]
  defaultFemaleIcon = 'this.src="' + require('@/assets/images/female.png') + '"'
  defaultmaleIcon = 'this.src="' + require('@/assets/images/male.png') + '"'
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 15
  }
  imgUrl = ''
  isOpenModal = false
  visitorId = 0
  visitorRemark = ''
  remarkLoading = false
  dialogIsShow = false
  buttonLoading = false
  pickMinDate = ''
  pickOptions = {
    disabledDate: (v: any) => {
      if (this.pickMinDate !== '') {
        let maxTime = moment(this.pickMinDate).add(3, 'months')
        const minTime = moment(this.pickMinDate).subtract(3, 'months')
        if (maxTime > moment().endOf('day')) {
          maxTime = moment().endOf('day')
        }
        return moment(v) > maxTime || moment(v) < minTime
      }
      return moment(v) > moment().endOf('day')
    },
    onPick: (date: any) => {
      this.pickMinDate = date.minDate
      if (date.maxDate) {
        this.pickMinDate = ''
      }
    }
  }
  // 获取门禁数据
  get doorGroups() {
    return CommonModule.doorGroups
  }

  async beforeCreate() {
    // 获取相关字典
    // 开门方式
    this.openDoorWayDicts = await getDicts('FACE_LOG_OPEN_TYPE')
    this.personTypeDicts = await getDicts('FACE_LOG_PERSON_TYPE')
    CommonModule.GetDoorGroups()
  }

  created() {
    this.getVisitorList()
  }

  searchFn() {
    this.listQuery.page = 1
    this.getVisitorList()
  }

  // 获取访客列表
  getVisitorList() {
    this.listLoading = true
    this.$api.visitor
      .getVisitorList({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        openDoorType: this.openDoorType !== '' ? Number(this.openDoorType) : '',
        enterDeviceId:
          this.enterDeviceId !== '' ? Number(this.enterDeviceId) : '',
        snapTimeStart: this.searchModel.value
          ? (this.searchModel.value as any)[0]
          : '',
        snapTimeEnd: this.searchModel.value
          ? (this.searchModel.value as any)[1]
          : ''
      })
      .then((res: any) => {
        this.listLoading = false
        const data = res.data
        this.tableData = data.data
        this.total = data.total
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 添加备注
  addRemark(remark: string) {
    this.remarkLoading = true
    this.$api.visitor
      .addRemark({
        id: this.visitorId,
        remark
      })
      .then((res: any) => {
        this.remarkLoading = false
        const data = res.data
        if (data.success) {
          this.$message.success('添加备注成功')
          this.getVisitorList()
          this.closeDialog()
        } else {
          this.$message.error(data.msg)
        }
      })
      .catch(() => {
        this.remarkLoading = false
        this.$message.error('添加备注失败')
      })
  }

  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, val)
  }

  // 批量导出
  doExport() {
    this.dialogIsShow = true
    // // TODO
    // const p = {
    //   exportType: 'visitor',
    //   openDoorType: this.openDoorType !== '' ? Number(this.openDoorType) : '',
    //   enterDeviceId:
    //     this.enterDeviceId !== '' ? Number(this.enterDeviceId) : '',
    //   snapTimeStart: this.searchModel.value
    //     ? (this.searchModel.value as any)[0]
    //     : '',
    //   snapTimeEnd: this.searchModel.value
    //     ? (this.searchModel.value as any)[1]
    //     : ''
    // }
    // getExportExcel(p, '进出记录导出.xlsx')
  }

  dialogConfirm() {
    this.buttonLoading = true
    const p = {
      exportType: 'visitor',
      openDoorType: this.openDoorType !== '' ? Number(this.openDoorType) : '',
      enterDeviceId:
        this.enterDeviceId !== '' ? Number(this.enterDeviceId) : '',
      snapTimeStart: this.searchModel.value
        ? (this.searchModel.value as any)[0]
        : '',
      snapTimeEnd: this.searchModel.value
        ? (this.searchModel.value as any)[1]
        : ''
    }
    if (!(this.searchModel.value && (this.searchModel.value as any)[0])) {
      const dayEnd = moment()
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss')
      const dayStart = moment(dayEnd)
        .subtract(3, 'months')
        .format('YYYY-MM-DD HH:mm:ss')
      p.snapTimeStart = dayStart
      p.snapTimeEnd = dayEnd
    }
    getExportExcel(p, '进入记录导出.xlsx')
      .then(() => {
        this.buttonLoading = false
        this.dialogIsShow = false
      })
      .catch(() => {
        this.buttonLoading = false
      })
  }

  // 查看图片
  viewImg(url: string) {
    this.imgUrl = url
  }

  // 关闭图片查看
  closeImg() {
    this.imgUrl = ''
  }

  // 关闭对话框
  closeDialog() {
    this.dialogIsShow = false
    this.isOpenModal = false
  }
  // 操作对话框后统一回调
  afterOpt(data: any) {
    this.addRemark(data)
  }

  toHistory() {
    this.$router.push('/visitors/visitor-history')
  }
}
